import React from 'react'
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useNavigate } from "react-router-dom";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { URLS } from "../../../src/components/Shared/Routes/Routes";
import { useState } from 'react';
import test1Img from '../../assets/img/test1.png';
import test2Img from '../../assets/img/test2.png';

const QuizCard = ({ title, quizId, attempts, passed, imageIndex, quizDescription }) => {

    let navigate = useNavigate();

    const token = localStorage.getItem("token");
    const [loader, setLoader] = useState(false);
    const axios = require("axios").default;

    const [open, setOpen] = React.useState(false);
    const [openLearnMore, setOpenLearnMore] = React.useState(false);

    const handleClickOpen = () => {
        console.log("id ", quizId);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickOpenLearnMore = () => {
        setOpenLearnMore(true);
    };

    const handleCloseLearnMore = () => {
        setOpenLearnMore(false);
    };

    const navigateToQuiz = () => {
        console.log("id ", quizId);
        QuizEnrollment();
    }

    const QuizEnrollment = async (event) => {
        setLoader(true);
        try {
            const response = await axios.post(process.env.REACT_APP_URL + URLS.quizEnrollment, {
                QuizId: quizId
            }, { headers: { "Authorization": `Bearer ${token}` } })
                .catch((error) => {
                    setLoader(false);
                    // props.showSnackbar("Please try again","error"); 
                    return error.response;
                });

            if (response.status == 200) {
                setLoader(false);
                // props.showSnackbar("Attempting Quiz", "success");
                localStorage.setItem("activeQuestion", 0);
                navigate(`/dashboard/quiz/${quizId}`);
            }

        }
        catch (err) {
            //   props.showSnackbar("Registration Failed please try again", "error");
        }
    }

    const imagePaths = [
        test1Img,
        test2Img,
        // Add more image paths as needed
    ];

    return (
        <>
            <Card sx={{ width: '250px' }}>
                <CardMedia
                    component="img"
                    alt=""
                    height="140"

                    image={imagePaths[imageIndex]}
                />
                <CardContent>
                    <Typography gutterBottom variant="h6" component="div" sx={{ height: 70 }}>
                        {title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        Status: {passed ? `Passed` : (!passed && attempts > 0) ? `Failed` : "Not Started"}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        Attempts: {attempts}
                    </Typography>
                </CardContent>
                <CardActions>
                    {passed ? <Button size="small" onClick={handleClickOpen} disabled={passed}>Test Passed</Button> :
                        <>
                            <Button size="small" onClick={handleClickOpen} disabled={passed}>Attempt Test</Button>
                            <Button size="small" onClick={handleClickOpenLearnMore}>Learn More</Button>
                        </>}

                </CardActions>
            </Card>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Your about to attempt the {title} quiz.
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Important Notice: Please ensure that you answer all questions and <strong> avoid closing or navigating back on your browser</strong>. <i>This will result in a failed attempt.</i> Good luck!
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                    <Button onClick={navigateToQuiz} autoFocus>
                        Attempt Quiz
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openLearnMore}
                onClose={handleCloseLearnMore}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {title} quiz.
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {quizDescription}
                        Once you start, <strong>avoid closing or navigating back on your browser</strong>. <i>This will result in a failed attempt.</i> Good luck!
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default QuizCard;